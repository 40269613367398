import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Temporary loading spinner for the premium card.
 * This should be replaced when Kinetic updates to allow resizing.
 */
@Component({
  selector: 'app-inline-loading-spinner',
  imports: [],
  styles: [
    `
      :host {
        --spinner-width: 1em;
        --spinner-height: 1em;
      }

      .spinner {
        width: var(--spinner-width);
        height: var(--spinner-height);
      }
      .rotate {
        animation: 500ms rotate infinite linear;
        transform-origin: center;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    `,
  ],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="spinner rotate">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineLoadingSpinnerComponent {}
